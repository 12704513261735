<template>
  <div class="campaign-page admin-page">
    <div class="container">
      <AdminHeader />

      <div class="heading">
        <v-breadcrumbs
          class="breadcrumbs"
          divider="<v-icon>mdi-chevron-right</v-icon>"
          :items="[
            {
              text: 'Kampagnen',
              to: '/admin/campaigns',
            },
            {
              text: campaign.name,
              to: $route.path,
            },
          ]"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :to="item.to == $route.path ? '' : item.to"
              exact
              exact-active-class="active"
              active-class=""
              :class="item.to == $route.path ? 'active' : ''"
            >
              <h2
                class="breadcrumbItem name"
                :class="{ editName: editName.active }"
                v-if="item.to == $route.path"
              >
                <span
                  class="nameText"
                  :contenteditable="editName.active"
                  @focusout="switchEditName(1)"
                  ref="nameText"
                >
                  {{ item.text }}</span
                >
                <v-btn icon x-small class="editNameBtn" @click="switchEditName">
                  <v-icon>{{ editName.active ? 'save' : 'edit' }}</v-icon>
                </v-btn>
                <v-dialog
                  v-model="editName.notSaved"
                  persistent
                  max-width="500"
                >
                  <v-card>
                    <v-card-title style="word-break: break-word;">
                      Änderung des Kampagnen-Namen speichern?
                    </v-card-title>
                    <v-card-text>
                      Du hast aus dem editierbaren Bereich des Namens ohne zu
                      speichern herausgeklickt. Soll die Änderung am Namen
                      gespeichert werden?
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="switchEditName(3)">Abbrechen</v-btn>
                      <v-btn text @click="switchEditName()">Speichern</v-btn>
                      <v-btn text @click="switchEditName(2)">Verwerfen</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </h2>
              <p class="breadcrumbItem" v-else>
                {{ item.text }}
              </p>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </div>

      <v-btn
        class="add"
        color="primary"
        elevation="0"
        :to="`/admin/campaigns/${$route.params.campaignid}/coupons/new`"
      >
        <v-icon left> add </v-icon>
        Gutschein hinzufügen
      </v-btn>

      <v-list two-line class="list">
        <v-list-item-group v-if="campaign && campaign.coupons">
          <template v-for="(item, index) in campaign.coupons.slice(0, 5)">
            <v-list-item
              class="item"
              :class="{ deactive: item.deactive }"
              :key="item.id"
              :to="
                `/admin/campaigns/${$route.params.campaignid}/coupons/${item.id}`
              "
            >
              <template v-slot:default>
                <v-list-item-content>
                  <v-list-item-title
                    class="itemTitle"
                    v-text="item.for"
                  ></v-list-item-title>

                  <v-list-item-subtitle>
                    <template v-if="item.mode == 'value'">
                      {{ item.value.toFixed(2) }} € Wertgutschein
                    </template>
                    <template v-if="item.mode == 'product'">
                      Produktgutschen für
                      {{
                        item.products.length == 1
                          ? item.products[0]
                          : item.products.length + ' Artikel'
                      }}
                    </template>
                    <template v-if="item.mode == 'discount'">
                      {{ item.discount * 100 }} % Rabattgutschein
                    </template>
                    <template
                      v-if="
                        !['value', 'product', 'discount'].includes(item.mode)
                      "
                    >
                      Gutschein
                    </template>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon>chevron_right</v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-divider
              class="mx-3"
              v-if="index < campaign.coupons.length - 1"
              :key="index"
            ></v-divider>

            <v-btn
              class="moreBtn"
              text
              elevation="0"
              v-if="index == 4"
              :key="index + 'more'"
              :to="`/admin/campaigns/${$route.params.campaignid}/coupons`"
            >
              <v-icon left>add</v-icon>
              {{ campaign.coupons.length - 5 }} weitere
            </v-btn>
          </template>
        </v-list-item-group>
      </v-list>

      <h3 class="subheading">Einstellungen</h3>
      <v-select
        :items="templateOptions"
        label="Vorlage"
        outlined
        class="templateSelect"
        v-model="campaign.template"
        @change="templateChange"
      ></v-select>

      <v-btn
        class="deleteBtn"
        color="error"
        elevation="0"
        @click="deleteCampaign()"
      >
        <v-icon left>delete</v-icon>
        Kampagne löschen
      </v-btn>
    </div>

    <v-dialog v-model="deleteCampaignDialog" persistent max-width="500px">
      <v-card>
        <v-card-title style="word-break: break-word;">
          Kampagne „{{ campaign.name }}“ wirklick löschen?
        </v-card-title>
        <v-card-text>
          Bist du sicher, dass du die Kampagne unwiderruflich löschen willst?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteCampaignDialog = false">Abbrechen</v-btn>
          <v-btn
            text
            color="error"
            @click="deleteCampaign(true)"
            :loading="deleteCampaignLoading"
            >Löschen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="couponsDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      content-class="couponsDialog"
    >
      <v-card class="card">
        <div class="container">
          <div class="top">
            <v-btn
              icon
              exact
              :to="`/admin/campaigns/${$route.params.campaignid}`"
            >
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <v-breadcrumbs
              class="breadcrumbs"
              divider="<v-icon>mdi-chevron-right</v-icon>"
              :items="[
                {
                  text: 'Kampagnen',
                  to: '/admin/campaigns',
                },
                {
                  text: campaign.name,
                  to: `/admin/campaigns/${$route.params.campaignid}`,
                },
                {
                  text: 'Gutscheine',
                  to: `/admin/campaigns/${$route.params.campaignid}/coupons`,
                },
              ]"
            >
              <template v-slot:divider>
                <v-icon>chevron_right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="item.to"
                  exact
                  exact-active-class="active"
                >
                  <h2 class="breadcrumbItem" v-if="item.to == $route.path">
                    {{ item.text }}
                  </h2>
                  <p class="breadcrumbItem" v-else>{{ item.text }}</p>
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </div>

          <v-list two-line class="couponList">
            <v-list-item-group v-if="campaign && campaign.coupons">
              <template v-for="(item, index) in campaign.coupons">
                <v-list-item
                  class="item"
                  :key="item.id"
                  :to="
                    `/admin/campaigns/${$route.params.campaignid}/coupons/${item.id}`
                  "
                >
                  <template v-slot:default>
                    <v-list-item-content>
                      <v-list-item-title
                        class="itemTitle"
                        v-text="item.for"
                      ></v-list-item-title>

                      <v-list-item-subtitle>
                        <template v-if="item.mode == 'value'">
                          {{ item.value.toFixed(2) }} € Wertgutschein
                        </template>
                        <template v-if="item.mode == 'product'">
                          Produktgutschen für
                          {{
                            item.products.length == 1
                              ? item.products[0]
                              : item.products.length + ' Artikel'
                          }}
                        </template>
                        <template v-if="item.mode == 'discount'">
                          {{ item.discount * 100 }} % Rabattgutschein
                        </template>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon>chevron_right</v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider
                  class="mx-3"
                  v-if="index < campaign.coupons.length - 1"
                  :key="index"
                ></v-divider>

                <v-btn
                  class="lessBtn"
                  text
                  exact
                  elevation="0"
                  v-if="index == campaign.coupons.length - 1"
                  :key="index + 'more'"
                  :to="`/admin/campaigns/${$route.params.campaignid}`"
                >
                  <v-icon left>remove</v-icon>
                  weniger anzeigen
                </v-btn>
              </template>
            </v-list-item-group>
          </v-list>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="error.active" persistent max-width="500">
      <v-card>
        <template v-if="error.code == 'notFound'">
          <v-card-title style="word-break: break-word;">
            Gutschein nicht gefunden
          </v-card-title>
          <v-card-text>
            Der Gutschein mit der angegebenen ID konnte in der Kampagne nicht
            gefunden werden. Du kannst diesen aber erstellen.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dismissErrorDialog">OK</v-btn>
          </v-card-actions>
        </template>
        <template v-if="error.code == 'unknownDoc'">
          <v-card-title style="word-break: break-word;">
            Unbekannter Fehler beim Aufruf des Gutscheins
          </v-card-title>
          <v-card-text>
            Als wir versucht haben den Gutschein mit der angegebenen ID
            aufzurufen, ist ein unerwarteter Feher aufgetreten. Bitte versuche
            es später erneut.<br />
            Fehlercode: {{ $route.query.code }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dismissErrorDialog">OK</v-btn>
          </v-card-actions>
        </template>
        <template v-if="error.code == 'deleteCampaignError'">
          <v-card-title style="word-break: break-word;">
            Unbekannter Fehler beim Löschen der Kampagne
          </v-card-title>
          <v-card-text>
            Als wir versucht haben die Kampagne "{{ campaign.name }}" zu
            löschen, ist ein unerwarteter Feher aufgetreten. Bitte versuche es
            später erneut.<br />
            Fehlercode: deleteCampaignError
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dismissErrorDialog">OK</v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.active"
      timeout="2000"
      @input="
        $event == false
          ? $router.replace({
              path: `/admin/campaigns/${$route.params.campaignid}`,
            })
          : null
      "
    >
      <template v-if="snackbar.code === 'couponDeleted'">
        Gutschein wird gelöscht...
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { db } from '@/firebase';
import AdminHeader from '@/components/AdminHeader';

export default {
  name: 'AdminCampaign',
  components: { AdminHeader },
  watch: {
    '$route.params.campaignid': {
      async handler(id) {
        try {
          let campaign = await this.$bind(
            'campaign',
            db.doc(`campaigns/${id}`)
          );
          if (campaign == null) {
            this.$router.replace({
              path: '/admin/campaigns',
              query: { error: 'notFound' },
            });
            return;
          }
        } catch (e) {
          this.$router.replace({
            path: '/admin/campaigns',
            query: { error: 'unknownDoc', code: e.code },
          });
          return;
        }
      },
      immediate: true,
    },
    '$route.name': {
      handler(name) {
        this.couponsDialog = name == 'AdminCampaignCoupons';
      },
      immediate: true,
    },
    '$route.query.error': {
      handler(code) {
        if (!code) return;
        this.error.active = true;
        this.error.code = code;
      },
      immediate: true,
    },
    '$route.query.snackbar': {
      handler(code) {
        if (!code) return;
        this.snackbar.active = true;
        this.snackbar.code = code;
      },
      immediate: true,
    },
  },
  data() {
    return {
      campaign: {},
      editName: {
        active: false,
        notSaved: false,
      },
      deleteCampaignDialog: false,
      deleteCampaignLoading: false,
      couponsMode: false,
      couponsDialog: false,
      error: {
        active: false,
        code: '',
      },
      snackbar: {
        active: false,
        code: '',
      },
    };
  },
  methods: {
    test(e) {
      console.log(e);
    },
    switchEditName(mode) {
      let focusNameText = () => {
        setTimeout(() => {
          const nameText = this.$refs?.nameText;
          if (!nameText) return;

          nameText.focus();

          // Set cursor position to end
          if (
            typeof window.getSelection != 'undefined' &&
            typeof document.createRange != 'undefined'
          ) {
            var range = document.createRange();
            range.selectNodeContents(nameText);
            range.collapse(false);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
          } else if (typeof document.body.createTextRange != 'undefined') {
            var textRange = document.body.createTextRange();
            textRange.moveToElementText(nameText);
            textRange.collapse(false);
            textRange.select();
          }
        });
      };

      if (mode === 2) {
        // Abort editing
        this.editName.active = false;
        this.editName.notSaved = false;

        const nameText = this.$refs?.nameText;
        if (!nameText) return;
        nameText.textContent = this.campaign.name;
        return;
      }
      if (mode == 3) {
        // Continue editing
        this.editName.notSaved = false;
        focusNameText();
        return;
      }

      if (!this.editName.active) {
        // Start editing name
        this.editName.active = true;
        focusNameText();
      } else {
        if (mode === 1) {
          // Focus out and not saved
          setTimeout(() => {
            if (this.editName.active) this.editName.notSaved = true;
          }, 200);
          return;
        }

        // Save edited name
        this.editName.active = false;
        this.editName.notSaved = false;

        const nameText = this.$refs?.nameText;
        if (!nameText) return;
        let newName = nameText.textContent.trim();

        if (newName.length == 0) {
          nameText.textContent = this.campaign.name;
          return;
        }

        this.$firestoreRefs.campaign.update({ name: newName });
      }
    },
    templateChange(value) {
      this.$firestoreRefs.campaign.update({ template: value });
    },
    async deleteCampaign(sure) {
      if (!sure) {
        this.deleteCampaignDialog = true;
      } else if (sure === true) {
        this.deleteCampaignLoading = true;
        try {
          await this.$firestoreRefs.campaign.delete();
          this.deleteCampaignDialog = false;
          this.$router.replace({ path: '/admin/campaigns' });
        } catch (e) {
          this.deleteCampaignDialog = false;
          this.error.active = true;
          this.error.code = 'deleteCampaignError';
        }
      }
    },
    dismissErrorDialog() {
      this.error.active = false;
      this.error.code = '';
      this.$router.replace({
        path: `/admin/campaigns/${this.$route.params.campaignid}`,
      });
    },
  },
  computed: {
    templateOptions() {
      return [
        {
          text: 'Adventsrätsel',
          value: 'adventsraetsel',
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/views/admin/admin.scss';

.couponsDialog {
  .card {
    background-color: #fafafa;
  }

  .container {
    display: block;
    margin: 0 auto;
    max-width: 600px;
  }
  p {
    margin: 0;
  }

  .top {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 24px 0 0;

    .breadcrumbs {
      padding: 0;
      margin-left: 16px;

      .active .breadcrumbItem {
        color: #000000;
      }

      .breadcrumbItem {
        font-size: 18px;
        font-weight: 500;
        text-align: left;
        color: rgba(#000000, 0.56);
      }
    }
  }

  .couponList {
    margin-top: 32px;
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid rgba(#000000, 0.1);
    overflow: hidden;

    .item {
      .itemTitle {
        font-weight: 500;
      }
    }

    .lessBtn {
      width: 100%;
      justify-content: flex-start;
      border-radius: 0;
      background-color: rgba(#000000, 0.04);
      margin-bottom: -8px;
    }
  }
}

.campaign-page {
  .container {
    .heading {
      .breadcrumbs {
        .name {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &.editName {
            border-bottom: 1px solid #428ecc;
            background-color: rgba(#000000, 0.1);
            border-radius: 4px 4px 0 0;
            padding: 0 8px 0 0;
          }

          .nameText {
            cursor: default;

            &[contenteditable='true'] {
              border: none;
              outline: none;
              padding: 2px 2px 2px 8px;
              caret-color: #428ecc;
              cursor: text;
            }
          }

          .editNameBtn {
            margin-left: 8px;
          }
        }
      }
    }
    .add {
      margin-bottom: 16px;
    }

    .list {
      border-radius: 8px;
      border: 1px solid rgba(#000000, 0.1);
      overflow: hidden;

      .item {
        &.deactive {
          opacity: 0.56;
        }

        .itemTitle {
          font-weight: 500;
        }
      }

      .moreBtn {
        width: 100%;
        justify-content: flex-start;
        border-radius: 0;
        background-color: rgba(#000000, 0.04);
        margin-bottom: -8px;
      }
    }

    .subheading {
      margin: 32px 0 24px 0;
      font-weight: 500;
      font-size: 16px;
    }

    .templateSelect {
      max-width: 250px;
      border-radius: 8px;
    }
  }
}
</style>
